import React from "react";
import bot from "../../assets/bot.svg";
import moment from "moment/moment";

function LeftChat({ content = "", time }) {
  let newLink = content.split("https")[1];
  let link = "";

  if (newLink) {
    if (newLink.includes(".pdf")) {
      newLink = newLink.split(".pdf")[0];
      link = `https${newLink}.pdf`;
    } else {
      link = `https${newLink}`;
    }
  }

  // Check if the content contains the link text and replace it with an anchor tag
  let contentWithLink = content;
  if (link) {
    contentWithLink = content.replace(
      link,
      `<a href="${link}" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">${link}</a>`
    );
  }

  return (
    <div className="py-2">
      <div className="rounded-xl  flex">
        <div className="flex border rounded-xl p-2">
          {/* <div className="chatBot_communi_chat content-center text-start">{content}</div> */}
          <div
            className="chatBot_communi_chat content-center text-start"
            dangerouslySetInnerHTML={{ __html: contentWithLink }}
          />
          <div className="flex flex-col justify-end items-end chatBot_communi_detail">
            <img className=" h-4" src={bot} alt="" />
            <p className="chatBot_communi_chat_date text-center">
              {" "}
              {moment(time).format("MMM-DD")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftChat;
