import React, { useState } from "react";
import { GoChevronDown } from "react-icons/go";

function Faq_questions({ answer, question }) {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="col-span-12 md:col-span-6 ">
      <div className="faq_Blocks rounded-2xl px-5 py-3">
        <div className="w-full flex gap-1 cursor-pointer"
          onClick={handleClick}>
          <div className="w-11/12 md:text-sm question text-left">{question}</div>
          <div className="w-1/12">
            <GoChevronDown
              id="dropButton"
              className="my-auto h-full" />
          </div>
        </div>
        {isVisible && <p className="faq_Blocks__innerText faq_light_text pt-2 text-left">{answer}</p>}
      </div>
    </div>
  );
}

export default Faq_questions;
