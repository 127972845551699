import React, { useEffect, useState } from "react";
import "./simplify.css";
import { Simplifysvg2, underline_2 } from "../../assets";
import { isValidEmail } from "../../utils/helper-functions";
import { isNullOrEmpty } from "../../utils/text-utils";
import { ToastContainer, toast } from "react-toastify";
import { mailSubscription } from "../../hooks/chat";
import { ErrorCodes } from "../../utils/enums";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import "react-toastify/dist/ReactToastify.css";

function Simplify() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubscribe = () => {
    if (isNullOrEmpty(email)) toast.info("Please enter an email");
    else if (!isValidEmail(email)) toast.warn("Enter valid email");
    else {
      let object = { email: email };
      setIsLoading(true);
      mailSubscription(object)
        .then(({ data }) => {
          setIsLoading(false);
          if (data.error_code === ErrorCodes.success) {
            setEmail("");
            toast.success("Subscribed successfully!");
          } else toast.warn("Some error occurred");
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error("Network Error");
        });
    }
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  return (
    <>
      <ToastContainer
        autoClose={1000}
        hideProgressBar={true}
        position="bottom-right"
      />
      <div className="Simplify_main_box">
        <div className="Simplify_box_in_box email_box_simplify">
          <div className="Simplify_box_in_box_detail simplify_text">
            Stay connected with Orbi, our intelligent AI chatbot. Subscribe
            below to receive the latest updates, news, and exclusive insights
            straight to your inbox. Don't miss out on the exciting advancements
            in Orbi - join our email list today!
          </div>
          <div className="Simplify_box_in_box_detail sign_up_free_button">
            <div className="Subscribe_text">
              <input
                className="Subscribe_text_email"
                type="email"
                placeholder="Subscribe to Email"
                value={email}
                onChange={handleInputChange}
              />
              <button className="sign_up_text" onClick={handleSubscribe}>
                {isLoading ? <LoadingSpinner /> : "Subscribe"}
              </button>
            </div>
          </div>
        </div>
        <div className="Simplify_box_in_box">
          <div className="Simplify_box_in_box_detail Simplifysvg2">
            <img src={Simplifysvg2} alt="Simplify" />
          </div>
          <div className="Simplify_box_in_box_detail">
            <div className="Simplify_orbix_text_main">
              Simplify Underwriting with Orbi
            </div>
            <div className="header_image_under_line_simplify">
              <img src={underline_2} alt="Underline" />
            </div>
            <div className="Simplify_orbix_text_main_detail">
              Orbi, our intelligent bot, simplifies and speeds up underwriting
              by automating decision-making and ensuring precise risk
              assessments. Enhance efficiency, reduce errors, and boost
              profitability with Orbi's advanced algorithms and vast insurance
              knowledge. Orbi also offers detailed carrier information, helping
              you choose the right partners based on their product offerings,
              relevance to the client, and reviews. Optimize your insurance
              operations and foster success with Orbi.
            </div>
            <div className="arrow_box_1">
              <div className="arrow">
                &#x2714;{" "}
                <span className="arrow_text">Automated Underwriting</span>
              </div>
              <div className="arrow">
                &#x2714;{" "}
                <span className="arrow_text">Precise Risk Assessments</span>
              </div>
              <div className="arrow">
                &#x2714;{" "}
                <span className="arrow_text">Informed Carrier Selection</span>
              </div>
              <div className="arrow">
                &#x2714;{" "}
                <span className="arrow_text">Operational Optimization</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Simplify;
