import {
  CATEGORIES_DATA,
  CHATS_LIST,
  CHAT_HEAD_CREATED,
  SELECTED_CAT_ID,
  SELECTED_CHAT_HEAD_ID,
} from "../ActionType";

const INITIAL_STATE = {
  categoriesData: [],
  selectedChatId: "",
  selectedCategoryId: "",
  chatsList: [],
  newChatHead: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATEGORIES_DATA:
      return { ...state, categoriesData: action.payload };
    case SELECTED_CHAT_HEAD_ID:
      return { ...state, selectedChatId: action.payload };
    case CHATS_LIST:
      return { ...state, chatsList: action.payload };
    case SELECTED_CAT_ID:
      return { ...state, selectedCategoryId: action.payload };
    case CHAT_HEAD_CREATED:
      return { ...state, newChatHead: action.payload };
    default:
      return state;
  }
};
