import React, { useEffect, useRef, useState } from "react";
import "./HearAboutUs.css";
import OrbixNavbar from "../../components/orbixNavbar/OrbixNavbar";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { getAnalyticsList, postHearFromList } from "../../hooks/userManagment";
import { ErrorCodes, Lookups } from "../../utils/enums";
import { ToastContainer, toast } from "react-toastify";
import { isNullOrEmptyArray } from "../../utils/text-utils";
import { USER_LOGIN_DATA } from "../../utils/constants";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { loginAction } from "../../redux/AppAction";

export default function HearAboutUs() {
  const { userData } = useSelector((state) => state.user);
  const navigate = useNavigate("");
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [list, setList] = useState([]);
  const [selectedOptions, setselectedOptions] = useState([]);

  useEffect(() => {
    fetchHearFroms();
  }, []);

  const toggleOptionTwo = (option) => {
    if (selectedOptions.includes(option)) {
      setselectedOptions(selectedOptions.filter((selected) => selected !== option));
    } else {
      setselectedOptions([...selectedOptions, option]);
    }
  };

  function fetchHearFroms() {
    setIsLoading(true);
    getAnalyticsList(Lookups.HEAR_FROM)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code === ErrorCodes.success) {
          setList(data.result);
        } else if (data.error_code === ErrorCodes.failed) {
          toast.warn("No record find");
        } else {
          toast.warn("Oops! Some error occured");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Oops! Some error occured");
      });
  }

  function onNext() {
    if (isNullOrEmptyArray(selectedOptions)) toast.warn("Please select an Option!");
    else {
      const user = JSON.parse(localStorage.getItem(USER_LOGIN_DATA));
      const dataObject = JSON.stringify(
        selectedOptions.map((item) => ({
          user_id: userData?._id || user?._id,
          hear_from_id: item?.value,
        }))
      );
      setIsPostLoading(true);
      postHearFromList(dataObject)
        .then(({ data }) => {
          setIsPostLoading(false);
          if (data.error_code === ErrorCodes.success) {
            let newUserData = { ...userData };
            newUserData.is_hear_from = true;
            dispatch(loginAction(newUserData));
            if (newUserData.is_user_subscribed) {
              navigate(routes.dashboard);
            } else {
              navigate(!newUserData.is_employee ? routes.subscription : routes.dashboard);
            }
          } else {
            toast.warn("Oops! Some error occured");
          }
        })
        .catch((err) => {
          setIsPostLoading(false);
          toast.error("Network Error!");
        });
    }
  }
  const ref = useRef(null);
  useEffect(() => {
    // window.scrollTo(0, 0);
    ref.current.scrollIntoView();
  }, []);

  return (
    <>
      <ToastContainer autoClose={2000} position="bottom-right" hideProgressBar />
      <div className="hearAboutUsMainContainer">
        <OrbixNavbar />

        <div ref={ref} className="hearAboutUsMainContainerHearAboutUs">
          <div className="yourRoleSpaceBtwContainer">
            <div className="hearAboutUsContainer">
              <h1 className="hearAboutUsHeadingContainer">How did you hear about us?</h1>
              <p className="hearAboutUsParaContainer">
                To personalize your experience, please provide us with some information about
                yourself and your objectives.
              </p>
            </div>

            <div className="containerHearAboutUs">
              <div className="options-list">
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  list.map((option) => (
                    <div
                      key={option?.value}
                      className={`option ${selectedOptions.includes(option) ? "selected" : ""}`}
                      onClick={() => toggleOptionTwo(option)}
                    >
                      <input
                        type="radio"
                        checked={selectedOptions.includes(option)}
                        onChange={() => toggleOptionTwo(option)}
                      />
                      <label className="optionLabel">{option?.label}</label>
                    </div>
                  ))
                )}
              </div>
              <div className="button-container">
                <button
                  onClick={() => {
                    navigate(routes.business_role);
                  }}
                  className="buttonBack"
                >
                  Back
                </button>

                <button onClick={onNext} className="button" disabled={isLoading || isPostLoading}>
                  {isPostLoading ? <LoadingSpinner /> : "Get Started"}
                </button>
              </div>
            </div>
          </div>
          <p className="hearAboutUsBottomPara">© 2024 Orbix. All Rights Reserved. </p>
        </div>
      </div>
    </>
  );
}
