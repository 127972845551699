import React from "react";
import "./footer.css";
import { FaXTwitter } from "react-icons/fa6";

import {
  logo,
  Facebook,
  Instagram,
  Twitter,
  Github,
  LinkedIn,
} from "../../assets";
import { scroller } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

function Footer() {
  const navigate = useNavigate();
  const scrollToElement = (element) => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
      <div className={"footer"} id="about">
        <div className={"Footer1"}>
          <div className={"Footer_Text"}>
            <div className={"orbix_logo"}>
              <img src={logo} alt="img" />
            </div>
            <div className={"footer_text_detail"}>
              Create an account, become lightyears ahead of the curve, and
              effortlessly turn every minute into days of work.
            </div>
            <div className={"footer_list"}>
              <div
                onClick={() => scrollToElement("features")}
                className={"footer_list_line"}
              >
                Product
              </div>
              <div
                onClick={() => scrollToElement("plans")}
                className={"footer_list_line"}
              >
                Pricing
              </div>
              {/* <div
                onClick={() => scrollToTop()}
                className={'footer_list_line'}
              >
                Pricing
              </div> */}
              <div
                onClick={() => {
                  navigate(routes.help);
                }}
                className={"footer_list_line"}
              >
                Help
              </div>
              <div
                onClick={() => {
                  navigate(routes.privacy_policy);
                }}
                className={"footer_list_line"}
              >
                Privacy
              </div>
            </div>
          </div>
          {/* <div className={'Footer_buttons'}>
            <div className={'playstore_button'}>
              <img src={Googleplay} />
            </div>
            <div className='playstore-button'>
                            <img src={iosplay} />
                        </div>
          </div> */}
        </div>
        <div className={"footer2"}>
          <div className={"Footer_Text all_right_orbix"}>
            © 2023 Orbix . All rights reserved.
          </div>
          <div className={"Footer_icon flex gap-5"} >
            <a href="https://facebook.com/100089883521987">
              <img src={Facebook} alt="img" />
            </a>
            <a href="http://x.com/TheOrbix">
            <FaXTwitter />

            </a>
           <a href="http://www.instragram.com/theorbix">
           <img src={Instagram} alt="img" />
           </a>
            <img src={Github} alt="img" />
            <img src={LinkedIn} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
