import React, { useEffect, useRef, useState } from "react";
import { noImage } from "../../assets";
import Modal from "react-modal";
import { getEmployeeAddRequiredThings } from "../../hooks/misc";
import Select from "react-select";
import { employeeAddCall, employeeEditCall, employeeGetEmployeeCall } from "../../hooks/employee";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils/constants";

const customStyles = {
  content: {
    borderRadius: "15px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function SecondWelcome_Add_Employee() {
  const ref = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useSelector((state) => state?.user);

  const [state, setState] = useState({
    editUserData: null,
    fullName: "",
    email: "",
    phone: "",
    countryList: [],
    country: "",
    state: "",
    address: "",
    city: "",
    zip: "",
    company: "",
    // role: "",
    isAlertPopup: false,
    alertMessage: "",
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    ref.current.scrollIntoView();
    getRequiredThings();
  }, []);

  useEffect(() => {
    if (location.state && state.countryList.length > 0) {
      employeeGetEmployeeCall(location.state._id)
        .then(({ data }) => {
          if (data.error_code == 0) {
            let result = data.result;
            setState((prevState) => ({
              ...prevState,
              editUserData: result,
              fullName: result.full_name,
              email: result.email,
              phone: result.phone,
              country: state.countryList.find((x) => x.value == result.country),
              state: result.region,
              city: result.city,
              address: result.address,
              zip: result.zipcode,
            }));
            setSelectedImage(result.image_path);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [location.state, state.countryList]);

  function getRequiredThings() {
    setIsLoading(true);
    getEmployeeAddRequiredThings()
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({ ...prevState, countryList: data.result.country_list }));
        } else setState((prevState) => ({ ...prevState, countryList: [] }));
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setSelectedImage(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  function isViewValid() {
    if (!state.fullName) onHandleAlertPopUp("Please enter full name");
    else if (!state.email) onHandleAlertPopUp("Please enter email address");
    else if (!state.phone) onHandleAlertPopUp("Please enter phone number");
    else if (!state.country) onHandleAlertPopUp("Please select country");
    else if (!state.state) onHandleAlertPopUp("Please enter state/region");
    else if (!state.city) onHandleAlertPopUp("Please enter city");
    else if (!state.address) onHandleAlertPopUp("Please enter address");
    else if (!state.zip) onHandleAlertPopUp("Please enter zip code");
    else if (!state.company) onHandleAlertPopUp("Please enter company");
    // else if (!state.role) onHandleAlertPopUp("Please enter role");
    else return true;
    return false;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isViewValid()) {
      let formData = new FormData();
      if (state.editUserData) {
        formData.append("id", state.editUserData._id);
        formData.append("user_id", state.editUserData.user_id);
      }
      formData.append("email", state.email);
      formData.append("phone", state.phone);
      formData.append(
        "user_plan_id",
        state.editUserData ? state.editUserData.user_plan_id : userData.subscribed_plan._id
      );
      formData.append("full_name", state.fullName);
      formData.append("country", state.country.value);
      formData.append("region", state.state);
      formData.append("city", state.city);
      formData.append("zip_code", state.zip);
      formData.append("address", state.address);
      if (typeof selectedImage != "string") formData.append("image_file", selectedImage);

      setIsLoading(true);
      if (state.editUserData) {
        employeeEditCall(formData)
          .then(({ data }) => {
            setIsLoading(false);
            if (data.error_code == 0) {
              alert("User updated.");
              navigate(-1);
            } else alert(data.message);
          })
          .catch((err) => {
            console.log("err", err);
            setIsLoading(false);
          });
      } else {
        employeeAddCall(formData)
          .then(({ data }) => {
            setIsLoading(false);
            if (data.error_code == 0) {
              alert("User added.");
              navigate(-1);
            } else alert(data.message);
          })
          .catch((err) => {
            console.log("err", err);
            setIsLoading(false);
          });
      }
    }
  };

  function onHandleAlertPopUp(message) {
    setState((prevState) => ({ ...prevState, isAlertPopup: true, alertMessage: message }));
  }

  return (
    <div ref={ref} className="w-full p-5">
      <div className="w-full flex lg:flex-row flex-col gap-2 ">
        <div className="w-full lg:w-1/3  md:px-5 ">
          <div className="w-full flex flex-col justify-start items-center rounded-lg h-[100%] shadow-lg py-10">
            <div className="md:w-3/5 px-2 md:px-0 bg-white m-auto flex xxs:flex-col justify-center items-center">
              <div
                className="w-16 h-16 md:w-24 md:h-24 rounded-full bg-white border border-dashed"
                onClick={handleImageClick}
              >
                <img
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full p-1"
                  src={
                    selectedImage
                      ? typeof selectedImage == "string"
                        ? BASE_URL.concat(selectedImage)
                        : URL.createObjectURL(selectedImage)
                      : noImage
                  }
                  alt=""
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                  style={{ display: "none" }}
                />
              </div>
              <p className="add_emp_upload_text pt-5">
                Allowed *.jpeg, *.jpg, *.png, *.gif Max size of 3.1 MB
              </p>
            </div>
            {/* <div className="pt-5 pb-5 md:pb-20 px-5">
              <h1 className="add_emp_email_heading">Email Verified</h1>
              <div className="flex gap-5">
                <p className="add_emp_email_text w-4/5">
                  Disabling this will automatically send the user a verification email
                </p>
                <label class="switch w-1/5 text-end flex justify-end">
                  <input type="checkbox" />
                  <span class="slider"></span>
                </label>
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-full lg:w-2/3 rounded-lg shadow-lg pt-2 pb-10 px-5 md:px-20  ">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 md:gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Full Name:
                  </label>
                  <input
                    className="emp_add_input py-2"
                    type="text"
                    name="fullName"
                    value={state.fullName}
                    onChange={handleChange}
                    placeholder="Enter your full name"
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Email address:
                  </label>
                  <input
                    className="emp_add_input"
                    type="email"
                    name="email"
                    disabled={state.editUserData}
                    value={state.email}
                    onChange={handleChange}
                    placeholder="Enter your email address"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Phone number:
                  </label>
                  <input
                    className="emp_add_input"
                    type="tel"
                    name="phone"
                    value={state.phone}
                    onChange={handleChange}
                    placeholder="Enter your phone number"
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2 mt-6">
                  {/* <label className=" bg-white w-fit flex text-[#919EAB] text-xs absolute top-3 left-3 ">
                    Country:
                  </label> */}
                  <Select
                    className="model__container__content__form__content__input__select"
                    placeholder="Select Country"
                    options={state.countryList}
                    value={state.country}
                    onChange={(value) =>
                      setState((prevState) => ({ ...prevState, country: value }))
                    }
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: { ...theme.colors, primary25: "#dee1e5", primary: "#919eab" },
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    State/Region:
                  </label>
                  <input
                    className="emp_add_input"
                    type="text"
                    name="state"
                    value={state.state}
                    onChange={handleChange}
                    placeholder="Enter your state"
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    City:
                  </label>
                  <input
                    className="emp_add_input"
                    type="text"
                    name="city"
                    value={state.city}
                    onChange={handleChange}
                    placeholder="Enter your city name"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Address:
                  </label>
                  <input
                    className="emp_add_input"
                    type="text"
                    name="address"
                    value={state.address}
                    onChange={handleChange}
                    placeholder="Enter your address"
                  />
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Zip code:
                  </label>
                  <input
                    className="emp_add_input"
                    type="text"
                    name="zip"
                    value={state.zip}
                    onChange={handleChange}
                    placeholder="Enter your zip code"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 md:gap-5">
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Company:
                  </label>
                  <input
                    className="emp_add_input"
                    type="text"
                    name="company"
                    value={state.company}
                    onChange={handleChange}
                    placeholder="Enter your company name"
                  />
                </div>
              </div>
              {/* <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col font-semibold text-[13px] gap-2">
                  <label className=" bg-white w-fit flex text-[#919EAB] text-xs relative top-3 left-3">
                    Role:
                  </label>
                  <input
                    className="emp_add_input"
                    type="text"
                    name="role"
                    value={state.role}
                    onChange={handleChange}
                    placeholder="Enter your role"
                  />
                </div>
              </div> */}
            </div>

            <div className="pt-5 flex justify-center md:justify-end">
              <button className="emp_add_button font-semibold" type="submit">
                {state.editUserData ? "Edit User" : "Create User"}
              </button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        isOpen={state.isAlertPopup}
        onRequestClose={() => {
          setState((prevState) => ({ ...prevState, isAlertPopup: !state.isAlertPopup }));
        }}
        style={customStyles}
      >
        <div className=" w-56 sm:w-60 md:w-96">
          <h2 className="text-blue-400"></h2>
          <div className="pb-6">
            <h1 className="font-semibold text-xl">Alert?</h1>
            <p className="text-xs pt-1">{state.alertMessage}</p>
          </div>
          <div className="w-full flex gap-3 justify-center md:justify-end">
            <button
              onClick={() => {
                setState((prevState) => ({ ...prevState, isAlertPopup: !state.isAlertPopup }));
              }}
              className="text-sm text-white rounded-full bg-[#328BDE] px-8 py-[7px] hover:bg-[#2d7ec9]"
            >
              Ok
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SecondWelcome_Add_Employee;
