import React from "react";
import "./PaymentPlan.css";
import { FaCheckCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";

export default function PaymentPlan({
  tagline,
  plan,
  price,
  duration,
  features,
  tag,
  amountText,
  cardBtn = "Get Started",
  align,
  isHome = false,
  disabled = false,
  onClickGetStarted,
}) {
  
  const navigate = useNavigate("");
  const location = useLocation("");
  return (
    <>
      <div className="pricing-card">
        {tag && <div className="tagline">{tagline}</div>}
        <h2 className="plan">{plan}</h2>
        <div className="price">
          <span className="amount">{price ? `$${price}` : ""}</span>
          <span className="amountText">{amountText}</span>
          <p className="duration">{duration}</p>
        </div>
        <ul className="features">
          {features.map((feature, index) => (
            <div style={{ alignItems: align ? "start" : "center" }} className="ulContainer">
              <FaCheckCircle
                className="checkIcon"
                size={align ? "60" : ""}
                style={{ marginLeft: align ? "20px" : "" }}
              />{" "}
              <li className="lists" key={index}>
                {feature}
              </li>
            </div>
          ))}
        </ul>
        <div className="getStartedButtonContainerDiv">
          <button
            className="get-started"
            disabled={disabled}
            onClick={() => {
              if (!isHome) navigate(routes.dashboard);
              else if ((location.pathname === routes.plans && cardBtn =='Get Started') || (location.pathname === '/' && cardBtn =='Get Started')) {
                navigate(routes.signup)}
              else if (price) {
                onClickGetStarted();
              }else if (cardBtn === 'Contact us') {
                navigate(routes.contact_us)
              }
              
            }}
          >
            {cardBtn}
          </button>
        </div>
      </div>
    </>
  );
}
