import React, { useEffect, useRef, useState } from "react";
import { noImage } from "../../assets";
import { useSelector } from "react-redux";

function Profile_Edit() {
  const { userData } = useSelector((state) => state?.user);

  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    firstName: userData?.name || "",
    email: userData?.email || "",
    phone: userData?.phone || "",
    address: userData?.address || "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="w-full h-full p-7">
      <div className="w-full h-full flex flex-col justify-start items-start gap-5">
        <div className="flex justify-center items-center gap-5">
          <div className="image_size w-28 h-28  rounded-full" onClick={handleImageClick}>
            <img
              className="image_size w-28 h-28 rounded-full"
              src={selectedImage || noImage}
              alt=""
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: "none" }}
            />
          </div>

          <div>
            <div className="upload_text">Upload Photo</div>
            <div className="upload_detail">300x300 and max 2mb</div>
          </div>
        </div>
      </div>

      <div className="w-3/5 pt-5 ">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-5 pb-5">
            <div className=" col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                First Name:
                <input
                  className="profile_input"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </label>
            </div>

            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Email address:
                <input
                  className="profile_input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Phone Number:
                <input
                  className="profile_input"
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </label>
            </div>
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Bio:
                <input
                  className="profile_input"
                  type="text"
                  name="bio"
                  value={formData.bio}
                  onChange={handleChange}
                />
              </label>
            </div>
          </div>
          <div className="pt-5">
            <button className="profile_button" type="submit">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Profile_Edit;
