import axios from "axios";
import { BASE_URL } from "../utils/constants";

export const axiosInstance = () => {
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            "Content-Type": "application/json",
        },
    });
};

export const axiosFormDataInstance = () => {
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
