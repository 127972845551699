import { axiosInstance } from "../axios-client/axiosClient";

export const getUserDescriptionDetailCall = async (user_id) => {
    let params = {}
    if (user_id) params.user_id = user_id
    const api = axiosInstance();
    return await api.get("/api/plan/get-user-subscription-detail", {
        params
    });
};

export const planAttachPaymentMethodCall = async (object) => {
    const api = axiosInstance();
    return await api.post("/api/plan/attach-payment-method", object);
};

export const planEditPaymentMethodCall = async (object) => {
    const api = axiosInstance();
    return await api.post("/api/plan/edit-payment-method", object);
};

export const planMakePaymentMethodDefaultCall = async (object) => {
    const api = axiosInstance();
    return await api.post("/api/plan/make-payment-method-default", object);
};

export const getClientSecretCall = async (data) => {
    const api = axiosInstance();
    return await api.post("/api/plan/buy-plan", data);
};

export const getReferralDiscountDetailCall = async (referral_code) => {
    let params = {}
    if (referral_code) params.referral_code = referral_code
    const api = axiosInstance();
    return await api.get("/api/plan/get-referral-discount-detail", {
        params
    });
};

export const planUnSubscribeCall = async (data) => {
    const api = axiosInstance();
    return await api.post("/api/plan/unsubscribe-plan", data);
};