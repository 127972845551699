import {
  USER_LOGIN,
  CATEGORIES_DATA,
  SELECTED_CHAT_HEAD_ID,
  CHATS_LIST,
  SELECTED_CAT_ID,
  CHAT_HEAD_CREATED,
  USER_LOGOUT,
} from "./ActionType";

export const loginAction = (data) => {
  return {
    type: USER_LOGIN,
    payload: data,
  };
};
export const logout = () => ({
  type: USER_LOGOUT,
});

export const categoriesDataState = (data) => {
  return {
    type: CATEGORIES_DATA,
    payload: data,
  };
};

export const selectedChatIdState = (data) => {
  return {
    type: SELECTED_CHAT_HEAD_ID,
    payload: data,
  };
};

export const chatListState = (data) => {
  return {
    type: CHATS_LIST,
    payload: data,
  };
};

export const selectedCatIdState = (data) => {
  return {
    type: SELECTED_CAT_ID,
    payload: data,
  };
};

export const chatHeadStatus = (data) => {
  return {
    type: CHAT_HEAD_CREATED,
    payload: data,
  };
};
