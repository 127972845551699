import "./SecondWelcome_Affiliate.css";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useSelector } from "react-redux";
import {
  getReferralDiscountDetailCall,
  getUserDescriptionDetailCall,
  planAttachPaymentMethodCall,
  planEditPaymentMethodCall,
} from "../../hooks/plan";
import Loader from "../../components/loading-spinner/Loader";
import { loadStripe } from "@stripe/stripe-js";
import { IoGiftOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCopy } from "react-icons/io";
import { PiUsersThreeFill } from "react-icons/pi";
import { BiSolidBadgeDollar } from "react-icons/bi";
import { getUserWalletDataCall } from "../../hooks/userManagment";

const features2 = [
  "Full Access to Orbi",
  "Personalized Sales Mentor",
  "Detailed Product Insights",
  "Automated Underwriting",
  "Carrier Selection Assistance",
  "Compliance Support",
];

const features3 = [
  "Full Access to Orbi",
  "Team Sales Training",
  "All features of the Starter plan",
  "Priority 24/7 support",
];

const features5 = [
  "Earn 15% all feature (Agent) commission for each referral that subscribes through your affiliate link. Plus a 21% discount on your monthly subscription.",
];

function SecondWelcome_Affiliate() {
  const { userData } = useSelector((state) => state?.user);
  const ref = useRef(null);

  // const referral_link = `https://orbix.ai/referral?REFERRALCODE=${userData?.subscribed_plan?.referral_code}`;
  const referral_link = `${window.location.origin}/referral?REFERRALCODE=${userData?.subscribed_plan?.referral_code}`;

  const [state, setState] = useState({
    referralDiscountData: null,
    myReferralsData: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    ref.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (activeTab == 2 && userData && userData?.subscribed_plan) {
      getReferralData();
    } else if (activeTab == 1 && userData && userData?.subscribed_plan) {
      getReferralDiscountData();
    }
  }, [activeTab]);

  function getReferralData() {
    setIsLoading(true);
    getUserWalletDataCall(userData.subscribed_plan._id)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({ ...prevState, myReferralsData: data.result }));
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  function getReferralDiscountData() {
    setIsLoading(true);
    getReferralDiscountDetailCall(userData.subscribed_plan.referral_code)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error_code == 0) {
          setState((prevState) => ({ ...prevState, referralDiscountData: data.result }));
        } else alert(data.message);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
      });
  }

  const handleCopy = () => {
    navigator.clipboard
      .writeText(referral_link)
      .then(() => {
        // setCopied(true);
        // setTimeout(() => {
        //   setCopied(false);
        // }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  function calculatePercentageOfWidth(amount, total_amount) {
    let width = "0%";
    if (amount && total_amount) {
      let perc = (amount / total_amount) * 100;
      console.log("perc", perc);
      return `${Math.abs(perc)}%`;
    } else return width;
  }

  return (
    <div ref={ref} className="col-span-12 w-screen flex justify-center items-center">
      <div className="w-11/12 pt-14 pl-14">
        <div className="w-full h-auto xxs:px-7 py-3">
          <div className="shadow-xl bg-white w-full min-h-[85vh] h-auto rounded-2xl flex flex-col items-center">
            <div className="w-full p-3 md:p-6">
              {/* <div className="w-full mb-2">
                <h1 className="md:text-xl font-bold">Billing Detail</h1>
                <p className="text-[10px] sm:text-xs">Please set your billing details</p>
              </div> */}

              <div className="flex justify-center items-center">
                <div className="flex bg-gray-200 rounded-full p-1">
                  <button
                    className={`${
                      activeTab === 1 ? "bg-white text-black" : "text-gray-500"
                    } px-4 py-2 rounded-full transition-colors duration-300`}
                    onClick={() => setActiveTab(1)}
                  >
                    Refer & Earn
                  </button>
                  <button
                    className={`${
                      activeTab === 2 ? "bg-white text-black" : "text-gray-500"
                    } px-4 py-2 rounded-full transition-colors duration-300`}
                    onClick={() => setActiveTab(2)}
                  >
                    My Referrals
                  </button>
                </div>
              </div>

              {activeTab === 1 ? (
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="w-full ">
                    <div className="p-5 rounded-lg shadow-lg">
                      <div className="text-[12px] sm:text-base font-semibold pb-5">
                        Select a hosting plan for your friend
                      </div>

                      <div className="flex flex-row gap-2">
                        <PaymentPlanCard
                          align
                          // tag
                          // tagline="72 hour trial"
                          plan="Affiliate"
                          price="999.00"
                          duration="OneTime Payment"
                          cardBtn="Refer To Friend"
                          features={features5}
                          isHome
                          disabled={isLoading}
                          bottomViewAffiliate
                          referralDiscount={state.referralDiscountData?.referral_discount}
                          refereeDiscount={state.referralDiscountData?.referee_discount}
                          // onClickGetStarted={() => getClientSecret("667d0612ca6657bebdd86f2b", 999.0)}
                        />

                        <PaymentPlanCard
                          plan="Agency"
                          price="499.00"
                          duration="Per Month/10 Users"
                          cardBtn="Refer To Friend"
                          features={features3}
                          isHome
                          disabled={isLoading}
                          bottomViewAffiliate
                          referralDiscount={state.referralDiscountData?.referral_discount}
                          refereeDiscount={state.referralDiscountData?.referee_discount}
                          // onClickGetStarted={() => getClientSecret("6659b1cf9bbb09e0e72c5f82", 499.0)}
                        />

                        <PaymentPlanCard
                          tag
                          tagline="Perfect for Personal use "
                          plan="Agent"
                          price="49.00"
                          duration="Per Month"
                          cardBtn="Refer To Friend"
                          features={features2}
                          isHome
                          disabled={isLoading}
                          bottomViewAffiliate
                          referralDiscount={state.referralDiscountData?.referral_discount}
                          refereeDiscount={state.referralDiscountData?.referee_discount}
                          //   onClickGetStarted={() => getClientSecret("667908fa74e357b6063d3b6f", 49.0)}
                        />
                      </div>
                    </div>

                    <div className="flex flex-row w-full gap-4 justify-between">
                      <div className="w-2/4 p-5 rounded-lg shadow-lg gap-2">
                        <div className="font-bold text-base">Share your referral link</div>
                        <div className="text-sm text-slate-400">
                          Refer a new client and earn a 20% commission. They'll get a 20% discount
                          too.
                        </div>

                        <div className="flex flex-row gap-4 mt-4">
                          <input
                            className="flex rounded-sm bg-blue-50 w-full p-2 text-slate-600 text-sm"
                            defaultValue={referral_link}
                            disabled={true}
                          />
                          <div
                            className="bg-blue-500 rounded-sm p-2 cursor-copy"
                            onClick={handleCopy}
                          >
                            <IoMdCopy color="white" size={25} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col w-full gap-4 ">
                  <div className="flex flex-row w-full gap-4 justify-between">
                    <div className="w-full p-5 rounded-lg shadow-lg gap-2">
                      <div className="gap-2 flex flex-row">
                        <h2 className="text-xl font-semibold">Commission payout</h2>
                        {/* <div>Commission Payout</div> */}
                      </div>

                      <div className="flex items-baseline justify-between mt-4">
                        <span className="text-4xl font-bold text-purple-600">
                          ${state.myReferralsData?.paid_out}
                        </span>
                        <span className="text-lg text-gray-500">
                          ${state.myReferralsData?.total_amount}
                        </span>
                      </div>
                      <div className="w-full bg-purple-100 h-2 rounded-2xl mt-2 mb-4">
                        <div
                          className="bg-purple-600 h-2 rounded-2xl"
                          style={{
                            width: calculatePercentageOfWidth(
                              state.myReferralsData?.paid_out,
                              state.myReferralsData?.total_amount
                            ),
                          }}
                        ></div>
                      </div>

                      <p className="text-gray-500 mb-4 text-sm">
                        You don't have any approved commissions yet
                      </p>
                      <p className="text-gray-500 text-sm">
                        Add payout method:{" "}
                        <a href="#" className="text-blue-600 hover:underline text-sm font-semibold">
                          PayPal
                        </a>{" "}
                        or{" "}
                        <a href="#" className="text-blue-600 hover:underline text-sm font-semibold">
                          Wire Transfer
                        </a>
                      </p>
                    </div>

                    <div className="w-full flex flex-col justify-center items-center p-5 rounded-lg shadow-lg gap-2">
                      <div className="rounded-full p-2 bg-slate-200">
                        <IoGiftOutline size={35} />
                      </div>
                      <div className="text-4xl font-bold">${state.myReferralsData?.amount}</div>
                      <p className="text-gray-500 text-sm">Total commission paid out</p>
                    </div>

                    <div className="w-full flex flex-col justify-center items-center p-5 rounded-lg shadow-lg gap-2">
                      <div className="rounded-full p-2 bg-slate-200">
                        <PiUsersThreeFill size={32} />
                      </div>
                      <div className="text-4xl font-bold">
                        {state.myReferralsData?.total_referrals}
                      </div>
                      <p className="text-gray-500 text-sm">Total referrals</p>
                    </div>
                  </div>

                  <div className="w-full flex flex-col justify-center items-center p-5 rounded-lg shadow-lg gap-2">
                    <div className="rounded-lg py-4 px-52 bg-slate-200">
                      <BiSolidBadgeDollar size={75} />
                    </div>
                    <div className="text-xl font-bold mt-4">
                      Refer your first cliet now and earn a 20% commission
                    </div>
                    <div className="text-gray-500 text-sm">
                      Refer your first cliet now and earn a 20% commission
                    </div>
                    <div className="flex flex-row gap-4 mt-4 w-1/3">
                      <input
                        className="flex rounded-sm bg-blue-50 w-full p-2 text-slate-600 text-sm"
                        defaultValue={referral_link}
                        disabled={true}
                      />
                      <div className="bg-blue-500 rounded-sm p-2 cursor-copy" onClick={handleCopy}>
                        <IoMdCopy color="white" size={25} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <Loader /> : null}
    </div>
  );
}

function PaymentPlanCard({
  tagline,
  plan,
  price,
  duration,
  features,
  tag,
  amountText,
  cardBtn = "Get Started",
  align,
  isHome = false,
  disabled = false,
  onClickGetStarted,
  bottomViewAffiliate = false,
  affiliateContainerHeight = "650",
  referralDiscount,
  refereeDiscount,
}) {
  const navigate = useNavigate("");

  function getAmountAfterDiscount() {
    let discountedPrice =
      (parseInt(price) / 100) * (refereeDiscount ? parseFloat(refereeDiscount) : 21);
    discountedPrice = price - discountedPrice;
    return discountedPrice.toFixed(2);
  }

  function getCommission() {
    return (
      (parseInt(price) / 100) *
      (referralDiscount ? parseFloat(referralDiscount) : 15)
    ).toFixed(2);
  }

  return (
    <>
      <div className="pricing-card-container">
        {tag && <div className="tagline">{tagline}</div>}
        <h2 className="plan">{plan}</h2>
        <div className="price">
          <span className="amount">{price ? `$${price}` : ""}</span>
          <span className="amountText">{amountText}</span>
          <p className="duration">{duration}</p>
        </div>
        <ul className="features">
          {features.map((feature, index) => (
            <div style={{ alignItems: align ? "start" : "center" }} className="ulContainer">
              <FaCheckCircle
                className="checkIcon"
                size={align ? "60" : ""}
                style={{ marginLeft: align ? "20px" : "" }}
              />{" "}
              <li className="lists" key={index}>
                {feature}
              </li>
            </div>
          ))}
        </ul>

        {bottomViewAffiliate ? (
          <div className="flex flex-col py-4 gap-2 w-5/6">
            <div className="flex flex-row justify-between rounded-xl bg-slate-100 p-2">
              <div className="flex flex-row gap-2 items-center">
                <div className="text-slate-400 text-sm">They Pay</div>
                <div className="rounded p-1 border text-slate-400 text-sm border-slate-400">
                  -{refereeDiscount ? refereeDiscount : "21"}%
                </div>
              </div>

              <div className="flex flex-row gap-2 items-center">
                <div className="text-slate-400 text-sm line-through font-semibold">${price}</div>
                <div className="text-black text-sm font-bold">${getAmountAfterDiscount()}</div>
              </div>
            </div>

            <div className="flex flex-row justify-between rounded-xl bg-green-200 p-2">
              <div className="flex flex-row gap-2 items-center">
                <IoGiftOutline color="green" />
                <div className="text-green-600 text-sm">Your Commission</div>
              </div>

              <div className="flex flex-row gap-2 items-center">
                <div className="text-green-600 text-sm font-bold">${getCommission()}</div>
              </div>
            </div>
          </div>
        ) : null}

        <button
          className="get-started"
          disabled={disabled}
          onClick={() => {
            if (!isHome) navigate(routes.dashboard);
            else if (price) {
              onClickGetStarted();
            }
          }}
        >
          {cardBtn}
        </button>
      </div>
    </>
  );
}

export default SecondWelcome_Affiliate;
