import React, { useState, useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectedCatIdState, selectedChatIdState } from "../../redux/AppAction";
import { CATEGORY_ID } from "../../utils/constants";
import { routes } from "../../utils/routes";

function Category({ catId, catTitle = "", chatHeadList = [] }) {
  const { selectedChatId } = useSelector((state) => state.chat);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [chatHeadId,setChaHeadId] = useState('')
  const [isOpen, setIsOpen] = useState(false); // State to manage open/close state of dropdown

  function chatHeadClick(chat_head_id) {
    setChaHeadId(chat_head_id);
    dispatch(selectedChatIdState(chat_head_id));
    dispatch(selectedCatIdState(catId));
    localStorage.setItem(CATEGORY_ID, catId);
    navigate(routes.chat_bot_main_chat, {
      state: { chat_head_id: chat_head_id, catId: catId },
    });
  }

  useEffect(() => {
    // No need for jsCall function if using React state to manage visibility
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4" key={catId}>
      <button
        type="button"
        className="collapsible flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <p className="pl-2">{catTitle}</p>
        <BsChevronDown className={`text-xs 2xl:text-xl mr-4 text-gray-900 font-light ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      {isOpen && (
        <div className="content">
          <ul>
            {chatHeadList.length === 0 ? (
              <li className="category-item flex text-xs justify-between rounded-xl font-semibold text-gray-600 py-1 hover:bg-gray-200 items-center">
                <div className="px-3">No Chats</div>
              </li>
            ) : (
              chatHeadList.map((item) => (
                <li
                  onClick={() => chatHeadClick(item?._id)}
                  key={item?._id}
                  style={{ cursor: "pointer" }}
                  className={`sm:w-32 md:w-[9rem] lg:w-48 xl:w-56 2xl:w-72 category-item flex text-xs justify-between rounded-xl font-semibold text-gray-600 py-2 ${selectedChatId === item?._id ? "bg-gray-200" : ""} hover:bg-gray-100 items-center`}
                >
                  <div className="px-3 whitespace-nowrap overflow-hidden text-ellipsis">{item?.title}</div>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Category;
