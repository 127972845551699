import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./NavbarNew.css";
import Fade from "react-reveal/Fade";
import { FiAlignRight, FiX } from "react-icons/fi";
import { routes } from "../../utils/routes.js";
import { useSelector } from "react-redux";
import orbixLogo from "../../assets/orbixLogo.svg";

const NavbarNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  let [activeSection, setActiveSection] = useState("home");
  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (userData && window.location.pathname == "/") {
      navigate(
        userData.is_verified
          ? userData.is_user_subscribed
            ? routes.dashboard
            : userData.is_user_business_role && userData.is_hear_from
            ? routes.subscription
            : !userData.is_user_business_role
            ? routes.business_role
            : !userData.is_hear_from
            ? routes.hear_from
            : routes.welcome
          : "/"
      );
    }
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveSection(sectionId);
    }
  };

  const handleNavigation = (sectionId) => {
    if (location.pathname === "/") {
      scrollToSection(sectionId);
    } else {
      setActiveSection((activeSection = sectionId));
      navigate("/", { state: { sectionId } });
    }
    setMenuOpen(false); // Close the menu after clicking a link
  };

  useEffect(() => {
    if (location.state && location.state.sectionId) {
      setActiveSection((activeSection = location.state.sectionId));
      scrollToSection(location.state.sectionId);
    }
  }, [location.state]);

  useEffect(() => {
    const sections = ["home", "about", "features", "plans"];
    const currentPath = location.pathname;

    if (currentPath === "/") {
      let scrollPosition;

      for (let section of sections) {
        if (activeSection == section) scrollPosition = window.scrollY + 60;
        const element = document.getElementById(section);
        if (
          element &&
          element.offsetTop <= scrollPosition &&
          element.offsetTop + element.offsetHeight > scrollPosition
        ) {
          setActiveSection(section);
        }
      }
    } else if (currentPath === routes.features) {
      setActiveSection("features");
    } else if (currentPath === routes.plans) {
      setActiveSection("plans");
    } else {
      setActiveSection("");
    }
  }, [location]);

  const handleGetInTouch = () => {
    navigate(routes.login);
  };

  return (
    <Fade top>
      <nav className="navbar">
        <div className="responsiveSide">
          <div
            onClick={() => {
              setActiveSection("home"); // Set active section to "home" when logo is clicked
              navigate("/");
            }}
            className="navbar-logo cursor-pointer"
          >
            {/* <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Orbix Logo" /> */}
            <img src={orbixLogo} alt="Orbix Logo" />
          </div>
          <div className="navbar-right">
            <button className="login-button" onClick={handleGetInTouch}>
              Login
            </button>
            <div className="menu-toggle">
              {menuOpen ? (
                <div className="Toggle_Btn_CLose">
                  <FiX color="black" size={30} onClick={() => setMenuOpen(false)} />
                </div>
              ) : (
                <div className="Toggle_Btn_Open">
                  <FiAlignRight color="black" size={30} onClick={() => setMenuOpen(true)} />
                </div>
              )}
            </div>
          </div>
        </div>

        <ul className={`navbar-links ${menuOpen ? "show" : ""}`}>
          <li
            className={activeSection === "home" ? "active" : ""}
            onClick={() => {
              handleNavigation("home");
              setMenuOpen(false);
            }}
          >
            Home
          </li>
          <li
            className={activeSection === "about" ? "active" : ""}
            onClick={() => {
              handleNavigation("about");
              setMenuOpen(false);
            }}
          >
            About
          </li>
          <li
            className={activeSection === "features" ? "active" : ""}
            onClick={() => {
              navigate(routes.features);
              setMenuOpen(false);
            }}
          >
            Features
          </li>
          <li
            className={activeSection === "plans" ? "active" : ""}
            onClick={() => {
              navigate(routes.plans);
              setMenuOpen(false);
            }}
          >
            Plans
          </li>
        </ul>
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
          <button className="login-button hide" onClick={handleGetInTouch}>
            Login
          </button>
          <button
            className="login-button hide"
            onClick={() => {
              navigate(routes.signup);
            }}
          >
            Sign Up
          </button>
        </div>
      </nav>
    </Fade>
  );
};

export default NavbarNew;
