import { axiosInstance } from "../axios-client/axiosClient";

export const getEmployeeAddRequiredThings = async () => {
    const api = axiosInstance();
    return await api.get("/api/misc/rt-employee-add");
};

export const miscGetSubDivisionsCall = async (country_code) => {
    const api = axiosInstance();
    let params = {}
    if (country_code) params.country_code = country_code
    return await api.get("/api/misc/get-subdivisions", {
        params
    });
};