import React, { useEffect, useRef, useState } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { BsEye, BsEyeSlash } from "react-icons/bs";

function Security() {
  // const ref = useRef(null);
  // useEffect(() => {
  //   // window.scrollTo(0, 0);
  //   ref.current.scrollIntoView();
  // }, []);
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-full h-full p-7">
      <div className="w-3/5 pt-5 ">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-12 gap-5 pb-5">
            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                New Password:
                <div className="relative">
                  <input
                    placeholder="Input Your Password"
                    id="newPassword"
                    className="profile_input"
                    type={newPasswordVisible ? "text" : "password"}
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                  <div
                    className="visible_button absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  >
                    {newPasswordVisible ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
                  </div>
                </div>
              </label>
            </div>

            <div className="col-span-12 md:col-span-6">
              <label className="flex flex-col font-semibold text-xs gap-2">
                Confirm New Password:
                <div className="relative">
                  <input
                    placeholder="Input Your Password"
                    className="profile_input"
                    type={confirmPasswordVisible ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                  <div
                    className="visible_button absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  >
                    {confirmPasswordVisible ? <BsEye size={18} /> : <BsEyeSlash size={18} />}
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="w-full flex justify-end ">
            <button className="security_button" type="submit">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Security;
